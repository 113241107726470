import { useState } from "react";

const initialStateDefault = {
    loading: true,
    error: false,
    loadingMsg: "",
    errorMsg: "",
    message: ""
}

export default function useUiState(initialStateProps) {
    const [uiState, setUiState] = useState({
        ...initialStateDefault,
        ...initialStateProps
    });

    return {uiState, setUiState : (obj) => {
        setUiState((state)=>{
            
            return ({
            ...state,
            ...obj
        })})
    }};
}



